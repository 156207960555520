import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseFormItem } from '@app/admin/base/form-item';
import { Const } from '@const/Const';
import { Utils } from '@services/utils';

function isTrackingSettingChanged(ts1, ts2) {
  if (ts1 && ts2 && ts1 !== ts2) return true;
  if (Utils.isBoolean(ts1) && Utils.isBoolean(ts2) && ts1 !== ts2) return true;
  return false;
}

@Component({
  selector: '[tracking-settings]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
})
export class TrackingSettings extends BaseFormItem {
  private _isEnabled = true;
  @Input() get isEnabled() {return this._isEnabled}
  set isEnabled(value) {
    this._isEnabled = value;
    this.setEnableFormGroup(value);
  }
  @Input() set form(value: FormGroup) {
    this.formInput = value;
    this.keys = Object.keys(this.formInput.controls);
  }
  @Input() isShowFormInput = true;

  public static get declaration(): FormGroupDeclaration {return {
    carrierAssignment: {label: 'Carrier Assignment', type: 'formGroup', childItem: {
      required: {label: 'Required', required: true, isChanged: isTrackingSettingChanged, initialValue: Const.CarrierAssignmentOptions.before_12hrs.value}
    }},
    etaToPickup: {label: 'ETA to Pickup', type: 'formGroup', childItem: {
      required: {label: 'Required', required: true, isChanged: isTrackingSettingChanged, initialValue: Const.ETAToPickupOptions.before_4hrs.value}
    }},
    pickupSuccessful: {label: 'Pickup Successful', type: 'formGroup', childItem: {
      required: {label: 'Required', required: true, isChanged: isTrackingSettingChanged, initialValue: Const.PickupSuccessfulOptions.after_1hr.value}
    }},
    etaToDelivery: {label: 'ETA to Delivery', type: 'formGroup', childItem: {
      required: {label: 'Required', required: true, isChanged: isTrackingSettingChanged, initialValue: Const.ETAToDeliveryOptions.before_1hr.value},
      frequency: {label: 'Frequency', required: true, isChanged: isTrackingSettingChanged, initialValue: Const.ETAToDeliveryFrequencyOptions.every_6hrs.value}
    }},
    deliverySuccessful: {label: 'Delivery Successful', type: 'formGroup', childItem: {
      required: {label: 'Required', required: true, isChanged: isTrackingSettingChanged, initialValue: Const.DeliverySuccessfulOptions.after_1hr.value}
    }},
    mustArriveAtPickupOnTime: {label: '', type: 'formGroup', childItem: {
      required: {label: 'Must Arrive at Pickup On Time', type: 'boolean', required: true, isChanged: isTrackingSettingChanged, initialValue: false}
    }},
    mustArriveAtDeliveryOnTime: {label: '', type: 'formGroup', childItem: {
      required: {label: 'Must Arrive at Delivery On Time', type: 'boolean', required: true, isChanged: isTrackingSettingChanged, initialValue: false}
    }},
  }}

  protected formGroupDeclaration: FormGroupDeclaration = TrackingSettings.declaration;
  private keys = [];
  get formInputKeys(): Array<string> {
    return this.keys;
  }

  listCarrierAssignmentOptions = Object.values(Const.CarrierAssignmentOptions);
  listETAToPickupOptions = Object.values(Const.ETAToPickupOptions);
  listPickupSuccessfulOptions = Object.values(Const.PickupSuccessfulOptions);
  listETAToDeliveryOptions = Object.values(Const.ETAToDeliveryOptions);
  listETAToDeliveryFrequencyOptions = Object.values(Const.ETAToDeliveryFrequencyOptions);
  listDeliverySuccessfulOptions = Object.values(Const.DeliverySuccessfulOptions);

  get isCreateNew(): boolean {
    return !this.model;
  }

  public getTrackingText(value, key = ""): string {
    const listAllOptions = {
      carrierAssignment: this.listCarrierAssignmentOptions,
      etaToPickup: this.listETAToPickupOptions,
      pickupSuccessful: this.listPickupSuccessfulOptions,
      etaToDelivery: this.listETAToDeliveryOptions,
      frequency: this.listETAToDeliveryFrequencyOptions,
      deliverySuccessful: this.listDeliverySuccessfulOptions
    }
    const data = listAllOptions[key] || [];
    let item: any = data.find(item => item.value === value);
    return item?.text || ""
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.isAdminReadOnlyRole){
      this.isEnabled = false;
    }
    this.setEnableFormGroup(this.isEnabled);
  }

  protected createFormInput(): void {
    // formInput phải được tạo từ component cha rồi truyền vào
  }
}
