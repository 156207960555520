import { Component, Input } from "@angular/core";
import { BaseDialog } from "@dialogs/base-dlg";
import { DateUtil } from "@services/date-utils";

@Component({
  selector: '[tracking-settings-dialog]',
  templateUrl: './dialog.html',
  styleUrls: []
})
export class TrackingSettingsDialog extends BaseDialog {
  @Input() shipments: any;
  private now = DateUtil.getUTCTimestamp();

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    console.log('UTC', DateUtil.displayLocalTime(this.now, {format: "YYYY-MM-DD HH:mm:ss", timezone: 'UTC'}))
  }

  modelTrackingSettings(item) {
    let data = {}
    const keys = [
      'carrierAssignment', 'etaToPickup', 'pickupSuccessful',
      'etaToDelivery', 'deliverySuccessful',
      'mustArriveAtPickupOnTime', 'mustArriveAtDeliveryOnTime'
    ];

    for(let key of keys) {
      let docKey = `${key}${item.parentId ? `.${item.parentId}` : ''}${key !== 'carrierAssignment' || !item.isMultiStop ? `.${item._id}` : ''}`;
      let issue = (item.issues || []).find(ele => ele.documentKey === docKey) || {};
      let time = this.now - (issue?.notifyTime || 0);
      let minutes = Math.floor(time/ 60000);
      let status = issue.status;
      if(status === "tracking") {
        status = minutes > 0 ? 'alert' : 'pending';
      }
      if(!issue.notifyTime) status = 'pending';
      data[key] = {
        ...issue,
        status,
        minutes,
        message: this.showLateMinutes(minutes),
        notiDate: issue?.notifyTime ? DateUtil.displayLocalTime(issue?.notifyTime, {format: "YYYY-MM-DD HH:mm:ss", timezone: 'UTC'}) : null,
        ...item?.trackingSettings[key]
      }
    }
    return { ...data, _id: item._id };
  }

  public showLateMinutes(minutes: number) {
    if(minutes <= 0) return '';
    let txt = '';
    let hours = Math.floor(minutes / 60);
    if (hours > 0) {
      txt = `${hours} hour${hours > 1 ? 's' : ''}`;
      minutes = minutes - hours * 60;
    }
    if (minutes > 0) {
      if (txt.length > 0) {
        txt += ' ';
      }
      txt += `${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
    return `${txt} Late`;
  }
}
