<ng-container *ngIf="isShowFormInput">
  <form [formGroup]="formInput" nz-form style="margin: 0px; padding: 0px;">
    <div class="group-header bottom20">
      <div class="title bottom5">Tracking Settings</div>
      <div class="description">The following settings will apply for each shipment on the route</div>
    </div>
    <div *ngFor="let key of ['carrierAssignment']" class="form-item">
      <form nz-form [formGroupName]="key" style="width: 100%;">
        <div nz-row>
          <div class="form-label-v2" nz-col nzSm="8" nzMd="6" nzLg="6" nzXl="4">
            {{getLabel(key)}}
          </div>
          <div nz-col nzSm="16" nzMd="18" nzMd="18" nzXl="20">
            <div *ngFor="let subKey of ['required']"  nz-row>
              <div class="form-label-v2" nz-col nzSm="8" nzMd="4">
                {{getLabel(fullKey(key, subKey))}}
              </div>
              <div nz-col nzSm="16" nzMd="20">
                <nz-select nzBackdrop="true" style="width: 220px;"
                [formControlName]="subKey" [nzPlaceHolder]="'Select'">
                  <nz-option *ngFor="let item of listCarrierAssignmentOptions"
                    [nzLabel]="item.text" [nzValue]="item.value"
                  >
                  </nz-option>
                </nz-select>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div *ngFor="let key of ['etaToPickup']" class="form-item">
      <form nz-form [formGroupName]="key" style="width: 100%;">
        <div nz-row>
          <div class="form-label-v2" nz-col nzSm="8" nzMd="6" nzLg="6" nzXl="4">
            {{getLabel(key)}}
          </div>
          <div nz-col nzSm="16" nzMd="18" nzMd="18" nzXl="20">
            <div *ngFor="let subKey of ['required']"  nz-row>
              <div class="form-label-v2" nz-col nzSm="8" nzMd="4">
                {{getLabel(fullKey(key, subKey))}}
              </div>
              <div nz-col nzSm="16" nzMd="20">
                <nz-select nzBackdrop="true" style="width: 220px;"
                [formControlName]="subKey" [nzPlaceHolder]="'Select'">
                  <nz-option *ngFor="let item of listETAToPickupOptions"
                    [nzLabel]="item.text" [nzValue]="item.value"
                  >
                  </nz-option>
                </nz-select>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div *ngFor="let key of ['pickupSuccessful']" class="form-item">
      <form nz-form [formGroupName]="key" style="width: 100%;">
        <div nz-row>
          <div class="form-label-v2" nz-col nzSm="8" nzMd="6" nzLg="6" nzXl="4">
            {{getLabel(key)}}
          </div>
          <div nz-col nzSm="16" nzMd="18" nzMd="18" nzXl="20">
            <div *ngFor="let subKey of ['required']"  nz-row>
              <div class="form-label-v2" nz-col nzSm="8" nzMd="4">
                {{getLabel(fullKey(key, subKey))}}
              </div>
              <div nz-col nzSm="16" nzMd="20">
                <nz-select nzBackdrop="true" style="width: 220px;"
                [formControlName]="subKey" [nzPlaceHolder]="'Select'">
                  <nz-option *ngFor="let item of listPickupSuccessfulOptions"
                    [nzLabel]="item.text" [nzValue]="item.value"
                  >
                  </nz-option>
                </nz-select>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div *ngFor="let key of ['etaToDelivery']" class="form-item">
      <form nz-form [formGroupName]="key" style="width: 100%;">
        <div nz-row>
          <div class="form-label-v2" nz-col nzSm="8" nzMd="6" nzLg="6" nzXl="4">
            {{getLabel(key)}}
          </div>
          <div nz-col nzSm="16" nzMd="18" nzMd="18" nzXl="20">
            <div nz-row>
              <ng-container *ngFor="let subKey of ['required']">
                <div class="form-label-v2" nz-col nzSm="8" nzMd="4" nzLg="4" nzXl="4">
                  {{getLabel(fullKey(key, subKey))}}
                </div>
                <div nz-col nzSm="16" nzMd="20" nzLg="20" nzXl="8">
                  <nz-select nzBackdrop="true" style="width: 220px;"
                  [formControlName]="subKey" [nzPlaceHolder]="'Select'">
                    <nz-option *ngFor="let item of listETAToDeliveryOptions"
                      [nzLabel]="item.text" [nzValue]="item.value"
                    >
                    </nz-option>
                  </nz-select>
                </div>
              </ng-container>
              <ng-container *ngFor="let subKey of ['frequency']">
                <div class="form-label-v2" nz-col nzSm="8" nzMd="4" nzLg="4" nzXl="4">
                  {{getLabel(fullKey(key, subKey))}}
                </div>
                <div nz-col nzSm="16" nzMd="20" nzLg="20" nzXl="8">
                  <nz-select nzBackdrop="true" style="width: 150px;"
                  [formControlName]="subKey" [nzPlaceHolder]="'Select'">
                    <nz-option *ngFor="let item of listETAToDeliveryFrequencyOptions"
                      [nzLabel]="item.text" [nzValue]="item.value"
                    >
                    </nz-option>
                  </nz-select>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div *ngFor="let key of ['deliverySuccessful']" class="bottom20 top10">
      <form nz-form [formGroupName]="key" style="width: 100%;">
        <div nz-row>
          <div class="form-label-v2" nz-col nz-col nzSm="8" nzMd="6" nzLg="6" nzXl="4">
            {{getLabel(key)}}
          </div>
          <div nz-col nzSm="16" nzMd="18" nzMd="18" nzXl="20">
            <div *ngFor="let subKey of ['required']"  nz-row>
              <div class="form-label-v2" nz-col nzSm="8" nzMd="4">
                {{getLabel(fullKey(key, subKey))}}
              </div>
              <div nz-col nzSm="16" nzMd="20">
                <nz-select nzBackdrop="true" style="width: 220px;"
                [formControlName]="subKey" [nzPlaceHolder]="'Select'">
                  <nz-option *ngFor="let item of listDeliverySuccessfulOptions"
                    [nzLabel]="item.text" [nzValue]="item.value"
                  >
                  </nz-option>
                </nz-select>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="bottom20">
      <form nz-form [formGroupName]="'mustArriveAtPickupOnTime'" style="width: 100%;">
        <label nz-checkbox formControlName="required">{{getLabel(fullKey('mustArriveAtPickupOnTime','required'))}}</label>
      </form>
    </div>
    <div class="bottom20">
      <form nz-form [formGroupName]="'mustArriveAtDeliveryOnTime'" style="width: 100%;">
        <label nz-checkbox formControlName="required">{{getLabel(fullKey('mustArriveAtDeliveryOnTime','required'))}}</label>
      </form>
    </div>
  </form>
</ng-container>

<ng-container *ngIf="!isShowFormInput">
  <div *ngIf="!model">
    <div class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No Tracking Settings data</div>
  </div>
  <div *ngIf="model" class="tracking-settings-info top30">
    <div class="box-with-title">
      <div class="box-title">Tracking Settings For Shipment #{{ model._id }}</div>
      <div class="box-content">
        <div class="item-info flex">
          <div class="label">Carrier Assignment</div>
          <div class="flex">
            <div class="key">Required</div>
            <div class="content">{{getTrackingText(model.carrierAssignment?.required, "carrierAssignment")}}</div>
            <div class="group-status">
              <div *ngIf="model.carrierAssignment?.status === 'completed'" class="setting-status-complete">
                <span nz-icon nzType="check-circle" nzTheme="fill"></span>
              </div>
              <div *ngIf="model.carrierAssignment?.status === 'alert'" class="setting-status-late">
                <span nz-icon nzType="exclamation-circle" nzTheme="fill"></span>
                <span class="note-description">{{model.carrierAssignment?.message}}</span>
              </div>
              <div *ngIf="model.carrierAssignment?.status === 'pending'" class="setting-status-pendding">
                <span nz-icon nzType="minus-circle" nzTheme="fill"></span>
              </div>
              <div class="alert-time" *ngIf="model.carrierAssignment?.notiDate">Alert Time: {{model.carrierAssignment?.notiDate}}</div>
            </div>
          </div>
        </div>
        <div class="item-info flex">
          <div class="label">ETA to Pickup</div>
          <div class="flex">
            <div class="key">Required</div>
            <div class="content">{{getTrackingText(model.etaToPickup?.required, "etaToPickup")}}</div>
            <div class="group-status">
              <div *ngIf="model.etaToPickup?.status === 'completed'" class="setting-status-complete">
                <span nz-icon nzType="check-circle" nzTheme="fill"></span>
              </div>
              <div *ngIf="model.etaToPickup?.status === 'alert'" class="setting-status-late">
                <span nz-icon nzType="exclamation-circle" nzTheme="fill"></span>
                <span class="note-description">{{model.etaToPickup?.message}}</span>
              </div>
              <div *ngIf="model.etaToPickup?.status === 'pending'" class="setting-status-pendding">
                <span nz-icon nzType="minus-circle" nzTheme="fill"></span>
              </div>
              <div class="alert-time" *ngIf="model.etaToPickup?.notiDate">Alert Time: {{model.etaToPickup?.notiDate}}</div>
            </div>
          </div>
        </div>
        <div class="item-info flex">
          <div class="label">Pickup Successful</div>
          <div class="flex">
            <div class="key">Required</div>
            <div class="content">{{getTrackingText(model.pickupSuccessful?.required, "pickupSuccessful")}}</div>
            <div class="group-status">
              <div *ngIf="model.pickupSuccessful?.status === 'completed'" class="setting-status-complete">
                <span nz-icon nzType="check-circle" nzTheme="fill"></span>
              </div>
              <div *ngIf="model.pickupSuccessful?.status === 'alert'" class="setting-status-late">
                <span nz-icon nzType="exclamation-circle" nzTheme="fill"></span>
                <span class="note-description">{{model.pickupSuccessful?.message}}</span>
              </div>
              <div *ngIf="model.pickupSuccessful?.status === 'pending'" class="setting-status-pendding">
                <span nz-icon nzType="minus-circle" nzTheme="fill"></span>
              </div>
              <div class="alert-time" *ngIf="model.pickupSuccessful?.notiDate">Alert Time: {{model.pickupSuccessful?.notiDate}}</div>
            </div>
          </div>
        </div>
        <div class="item-info flex">
          <div class="label">ETA to Delivery</div>
          <div>
            <div class="flex bottom5">
              <div class="key">Required</div>
              <div class="content">{{getTrackingText(model.etaToDelivery?.required, "etaToDelivery")}}</div>
              <div class="group-status">
                <div *ngIf="model.etaToDelivery?.status === 'completed'" class="setting-status-complete">
                  <span nz-icon nzType="check-circle" nzTheme="fill"></span>
                </div>
                <div *ngIf="model.etaToDelivery?.status === 'alert'" class="setting-status-late">
                  <span nz-icon nzType="exclamation-circle" nzTheme="fill"></span>
                  <span class="note-description">{{model.etaToDelivery?.message}}</span>
                </div>
                <div *ngIf="model.etaToDelivery?.status === 'pending'" class="setting-status-pendding">
                  <span nz-icon nzType="minus-circle" nzTheme="fill"></span>
                </div>
                <div class="alert-time" *ngIf="model.etaToDelivery?.notiDate">Alert Time: {{model.etaToDelivery?.notiDate}}</div>
              </div>
            </div>
            <div class="flex">
              <div class="key">Frequency</div>
              <div class="content">{{getTrackingText(model.etaToDelivery?.frequency, "frequency")}}</div>
            </div>
          </div>
        </div>
        <div class="flex top10 bottom20">
          <div class="label">Delivery Successful</div>
          <div class="flex">
            <div class="key">Required</div>
            <div class="content">{{getTrackingText(model.deliverySuccessful?.required, "deliverySuccessful")}}</div>
            <div class="group-status">
              <div *ngIf="model.deliverySuccessful?.status === 'completed'" class="setting-status-complete">
                <span nz-icon nzType="check-circle" nzTheme="fill"></span>
              </div>
              <div *ngIf="model.deliverySuccessful?.status === 'alert'" class="setting-status-late">
                <span nz-icon nzType="exclamation-circle" nzTheme="fill"></span>
                <span class="note-description">{{model.deliverySuccessful?.message}}</span>
              </div>
              <div *ngIf="model.deliverySuccessful?.status === 'pending'" class="setting-status-pendding">
                <span nz-icon nzType="minus-circle" nzTheme="fill"></span>
              </div>
              <div class="alert-time" *ngIf="model.deliverySuccessful?.notiDate">Alert Time: {{model.deliverySuccessful?.notiDate}}</div>
            </div>
          </div>
        </div>
        <div class="flex bottom10">
          <div style="align-self: center; width:260px">Must Arrive at Pickup On Time</div>
          <div class="group-status">
            <div *ngIf="model.mustArriveAtPickupOnTime?.status === 'completed'" class="setting-status-complete">
              <span nz-icon nzType="check-circle" nzTheme="fill"></span>
            </div>
            <div *ngIf="model.mustArriveAtPickupOnTime?.status === 'alert'" class="setting-status-late">
              <span nz-icon nzType="exclamation-circle" nzTheme="fill"></span>
              <span class="note-description">{{model.mustArriveAtPickupOnTime?.message}}</span>
            </div>
            <div *ngIf="model.mustArriveAtPickupOnTime?.status === 'pending'" class="setting-status-pendding">
              <span nz-icon nzType="minus-circle" nzTheme="fill"></span>
            </div>
            <div class="alert-time" *ngIf="model.mustArriveAtPickupOnTime?.notiDate">Alert Time: {{model.mustArriveAtPickupOnTime?.notiDate}}</div>
          </div>
        </div>
        <div class="flex bottom10">
          <div style="align-self: center; width:260px">Must Arrive at Delivery On Time</div>
          <div class="group-status">
            <div *ngIf="model.mustArriveAtDeliveryOnTime?.status === 'completed'" class="setting-status-complete">
              <span nz-icon nzType="check-circle" nzTheme="fill"></span>
            </div>
            <div *ngIf="model.mustArriveAtDeliveryOnTime?.status === 'alert'" class="setting-status-late">
              <span nz-icon nzType="exclamation-circle" nzTheme="fill"></span>
              <span class="note-description">{{model.mustArriveAtDeliveryOnTime?.message}}</span>
            </div>
            <div *ngIf="model.mustArriveAtDeliveryOnTime?.status === 'pending'" class="setting-status-pendding">
              <span nz-icon nzType="minus-circle" nzTheme="fill"></span>
            </div>
            <div class="alert-time" *ngIf="model.mustArriveAtDeliveryOnTime?.notiDate">Alert Time: {{model.mustArriveAtDeliveryOnTime?.notiDate}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
